import React from "react"
import { PageWrapper } from "~components/Core"
import PrivacySection from "~sections/utility/Legal/PrivacySection"
import FooterSection from "~sections/utility/Footer"

export default function Privacy() {
  const header = {
    headerClasses: "position-relative",
  }
  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <PrivacySection />
      <FooterSection />
    </PageWrapper>
  )
}
